ul.pagination {
    margin-bottom: 0;
}

.container-card.search-return-content table th i {
    color: #037cb7;
    font-size: 0.6rem;
}
.dropdown-heading-dropdown-arrow.gray,
.dropdown-search-clear-icon.gray {
    color: #037cb7 !important;
}
.search-emp .rmsc .dropdown-container {
    border: none;
    background: none;
}

.container-content table,
.container table {
    padding: 1rem;
    margin: 0rem 0;
    width: 100%;
}

.container-content table tr th,
.container table tr th {
    font-size: 12.8px;
    padding-bottom: 10px;
    white-space: nowrap;
    font-weight: 900;
}

.container table tr th,
.container table tr td {
    border-bottom: 1px solid #cdcdcd;
    color: #525252;
    padding-left: 10px;
    padding-right: 10px;
}

.container table tr td.border-0 {
    border: none !important;
}

.container table tr td {
    padding-top: 20px;
    padding-bottom: 20px;
    width: "auto";
    background-color: #f6f8fa;
    vertical-align: middle;
}

.container-card.search-return-content table td {
    vertical-align: middle;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
}

.container table tr td a {
    color: #525252;
    text-decoration: underline !important;
}

tbody tr td:first-child,
thead tr th:first-child {
    padding-left: 20px;
}

tbody tr td:last-child,
thead tr th:last-child {
    padding-right: 20px;
}

.vertical-align-top.tr td {
    vertical-align: top !important;
}

.container table tr.even td {
    background-color: #fff;
}

.container table td.col-last {
    text-align: right;
}

.search-return-content .page-item.active .page-link {
    background-color: #037cb7;
    border: none;
    border-radius: 0.25rem;
}

.search-return-content .page-item.disabled .page-link {
    color: #cdcdcd;
}

.container .selitems {
    position: relative;
    margin-left: 1rem;
    font-size: 0.75rem;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.note {
    font-size: 0.75rem;
    color: #525252;
    margin-left: 20px;
    margin-top: 20px;
}

.container-content .table-container th {
    position: sticky;
    top: 0;
    background: white;
    border-bottom: 1px solid #cdcdcd;
}

.container-card.search-return-content table.table-container td {
    vertical-align: top;
}

.container-heading .main-heading .sub-heading01,
.container-heading .main-heading .sub-heading02 {
    display: inline-block;
}

.container-heading .main-heading .sub-heading01 {
    width: 25%;
    vertical-align: top;
}
.sub-heading02 a {
    color: #fff;
}
.container-heading .main-heading .sub-heading02 {
    width: 75%;
}
.sub-heading02 a:hover {
    color: #fff;
    text-decoration: underline;
}

.container-card .search-emp h3 {
    display: inline-block;
    font-size: 1.25rem;
    margin-right: 1.9375rem;
    margin-bottom: 0.875rem;
}

.container-card.container-emp .searchbtns {
    top: 1.6rem;
}
.container-card.container-emp .errormsg {
    float: left;
}

#react-select-3-listbox {
    display: block;
}

.page-item.disabled .page-link {
    border: none;
}

.container-content .selOrg .css-1s2u09g-control {
    border: none;
    background: none;
}

#react-select-3-listbox li:hover {
    background-color: #037cb7;
}

.e-dropdownbase .e-list-item.e-item-focus,
.e-dropdownbase .e-list-item.e-active,
.e-dropdownbase .e-list-item.e-active.e-hover,
.e-dropdownbase .e-list-item.e-hover {
    background-color: #1f9c99;
    color: #2319b8;
}

.selOrg .css-qc6sy-singleValue {
    text-transform: capitalize;
}

.notify-norecords p {
    margin-bottom: 0;
}

.container table tr td .fa-eye,
.container table tr td .fa-eye-slash {
    margin-left: 10px;
}

.container table tr td .part-eye,
.container table tr td .full-eye {
    color: #037cb7;
}

.container table tr td .mask-eye {
    color: #5e9e42;
}

.mask-eye {
    color: #5e9e42;
}

.fixed-header {
    max-height: 44rem;
    overflow-y: auto;
    overflow-x: hidden;
}

.relatedOrg .dropdown-content::-webkit-scrollbar-track,
.fixed-header::-webkit-scrollbar-track {
    background: var(--neutral-04, #f8f8f8) !important;
}

.container-content .relatedOrg .dropdown-content::-webkit-scrollbar-thumb,
.fixed-header::-webkit-scrollbar-thumb {
    border-radius: 8px !important;
    background: var(--neutral-04, #757575) !important;
}

.organizationContainer {
    padding-left: 20px !important;
    padding-right: 20px !important ;
}

.logoCard {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 24px;
}
.logoOrgContainer {
    position: sticky;
    top: 120px;
    z-index: 100;
}

.reportfilter .rmsc .dropdown-container {
    /* commented for future reference */
    /* border-bottom: 1px solid !important; */
    background: none !important;
    border-radius: 0px !important;
    border-top: 0px !important;
    border-left: none !important;
    border-right: none !important;
    transition: border-bottom 0.3s ease;
}

.reportfilter .rmsc .dropdown-heading {
    padding: 0px !important;
    color: #ffffff !important;
    margin-top: -12px !important;
}

.reportfilter .dropdown-heading-value span {
    max-width: fit-content !important;
}

/* .reportfilter .rmsc .dropdown-container:focus-within {
    box-shadow: none !important;
} */
.rmsc .options {
    color: #525252 !important;
}

.reportfilter .dropdown-heading-dropdown-arrow.gray {
    color: #fff !important;
}
.reportfilter .dropdown-search-clear-icon.gray {
    color: #fff !important;
}
.reportfilter .rmsc .dropdown-content {
    padding-top: 0px !important;
    z-index: 10000 !important;
}

.reportfilter .rmsc .dropdown-content input[type="checkbox"] {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    flex-basis: 24px;
    min-width: 24px;
    align-self: flex-start;
}
