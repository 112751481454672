@import "bootstrap/dist/css/bootstrap.min.css";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    min-height: 100%;
}

a {
    text-decoration: none;
    color: #037cb7;
}

ul li {
    list-style: none;
}

.container-heading .main-heading {
    background-color: #015294;
    box-shadow: 0px 3px 9px #00000080;
    border-radius: 0.5rem;
    opacity: 1;
    padding: 1.5rem 0 1.5rem 1.5rem;
    margin-bottom: 1.875rem;
}

.container-heading .main-heading h3 {
    color: #fff;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    display: block;
}

.container-heading .main-heading h4 {
    color: #fff;
    font-size: 0.8rem;
    margin-bottom: 0.25rem;
    display: block;
}
.container-heading .main-heading p {
    color: #fff;
    font-size: 1rem;
    margin-bottom: 0;
    display: block;
}

.employee-record .container-heading .main-heading p {
    width: auto;
}

@media only screen and (min-width: 360px) and (max-width: 499px) {
    header {
        position: static;
    }

    .container-header i.fa-bars {
        font-size: 2rem;
        padding-right: 1.25rem;
        display: inline-block !important;
        padding-left: 0.5625rem;
        position: relative;
        top: 0.2rem;
    }

    .container-header img.logo {
        position: relative;
        top: -0.5rem;
    }

    .container-header .font-icons i {
        font-size: 2rem;
    }

    span.title-bar {
        border-left: 3px solid #525252;
        margin-left: 0.6875rem;
        padding-right: 0.875rem;
        top: 0;
    }

    .container-header span.title {
        top: 0;
    }

    .container-header span.txt-log,
    .container-header .fa-caret-down {
        display: none;
    }

    .container-header .font-icons {
        display: block;
        position: absolute;
        right: 0.4rem;
        top: 1.2rem;
    }

    .row-search div.select-search,
    .row-search div.select-field {
        margin-top: 0.875rem;
        width: 98%;
    }

    .container-content .selOrg,
    .container-content input[type="text"] {
        width: 100%;
    }

    .container-brdcrmbs {
        display: none;
    }

    .fields-search {
        display: block;
    }

    .row-search label,
    form label {
        font-size: 0.875rem;
        padding-bottom: 0.53125rem;
    }

    .selOrg .css-qc6sy-singleValue {
        font-size: 0.875rem;
    }

    .container button.btn-primary {
        font-size: 0.875rem;
    }

    .container button.btn-outline {
        font-size: 0.875rem;
    }

    .container button.btn-select {
        width: 41%;
    }

    .container-content .searchbtns {
        margin-top: 1.25rem;
        margin-bottom: 0;
        top: 0;
    }

    .container-card.search-return-content {
        margin-top: 1.875rem;
    }

    .container-card.search-return-content table {
        margin-top: 0;
    }

    .container-card.search-return-content h3 {
        margin-left: 0.625rem;
        font-size: 1rem;
    }

    .container-card.search-return-content table tr th,
    .container-card.search-return-content table tr td {
        padding-left: 0.625rem;
    }

    .container-card.search-return-content table tr td {
        font-size: 0.875rem;
    }

    .btn-select {
        margin-right: 0.625rem;
        left: 0;
    }

    .container-content .search-emp .searchbtns button.btn-clear {
        margin-top: 1rem;
    }

    .container-heading .main-heading {
        margin-top: 1.25rem;
        margin-bottom: 1.3125rem;
        padding: 0.5rem 0.625rem 0.8125rem;
    }

    .container-card.container-emp {
        padding: 0.5rem 0.625rem 0.8125rem;
    }

    .container-heading .main-heading h3 {
        font-size: 1rem;
    }

    .container-heading .main-heading h4,
    .container-heading .main-heading p {
        font-size: 0.875rem;
    }

    .employee-record .container-heading .main-heading p {
        margin-bottom: 0.6rem;
    }

    .container-card .search-emp h3 {
        font-size: 1rem;
    }

    .rmsc span.gray {
        font-size: 0.875rem;
    }

    .txt-searchopt {
        width: 45%;
    }

    .container-content .search-emp .searchbtns {
        display: block;
    }

    .container .search-emp button.secondary.btn-clear {
        display: block;
        margin-top: 1rem;
    }

    .container-heading .main-heading .sub-heading01,
    .container-heading .main-heading .sub-heading02 {
        display: inline-block;
        width: 50%;
    }

    .container-heading .main-heading .sub-heading02 p {
        width: 10.5rem;
    }

    .container-content .select-searchopt .selectOpt,
    .container-emp .search-emp .relatedOrg {
        width: 100%;
    }

    .search-emp-opt {
        margin-right: 0;
        width: 100%;
    }

    .container-content .txt-searchopt input[type="text"] {
        width: 100%;
    }

    .search-emp-opt .select-searchopt {
        display: inline-block;
        margin-right: 1rem;
        width: 45%;
    }

    .search-emp-opt .txt-searchopt {
        display: inline-block;
        width: 45%;
    }

    .search-emp-opt .select-searchopt label {
        width: 100%;
    }

    .search-emp-opt .txt-searchopt input[type="textbox"] {
        width: 100%;
    }

    table.table-container {
        width: 100%;
    }

    .container-content .table-container tr td {
        width: auto;
    }

    .container-content .table-container thead {
        position: absolute;
        clip: rect(1px, 1px, 1px, 1px);
        padding: 0;
        border: 0;
        height: 1px;
        width: 1px;
        overflow: hidden;
    }
    .container-content .table-container tbody,
    .container-content .table-container tr,
    .container-content .table-container th,
    .container-content .table-container td {
        display: block;
        padding: 0;
        text-align: left !important;
        white-space: normal;
    }
    .container-content .table-container tr td,
    .container-content .table-container tr th {
        padding: 4px 16px;
        overflow: hidden;
        position: relative;
        vertical-align: top;
        text-align: left;
        border-top: none;
        border-bottom: none;
        font-size: 14px;
        width: 100%;
        white-space: normal;
    }
    .container-content .table-container th[scope="row"] {
        width: 100%;
        text-align: center;
        display: block;
        background-color: #b3bfc6;
        margin: 0 auto;
        padding: 0.5em 0;
    }

    .container-content .table-container td[data-header]:before {
        content: attr(data-header);
        display: block;
        float: left;
        width: 50%;
        font-weight: bold;
        text-align: left;
    }
    .container-content .table-container td[data-header] > * {
        display: contents;
        width: 50%;
        float: right;
        clear: right;
        margin-top: 0;
        margin-bottom: 0;
    }
    .container-content .table-container td[data-header]:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 50%;
        padding-bottom: 200%;
        display: block;
    }

    .pagination {
        justify-content: center;
    }

    .container .items {
        top: -1.5rem;
    }

    .container .selitems {
        top: -1.5rem;
    }

    .employee-record .main-heading .col {
        width: 50%;
    }

    .container.container-leave .col-det {
        width: 50%;
    }

    .container-content .table-container tr {
        border-top: 1px solid #cdcdcd;
    }

    .container-content .table-container {
        border-bottom: 1px solid #cdcdcd;
        padding-top: 4px;
    }
}

/* .scrollbar-container.body {
    height: 44rem;
} */

.progress-bar-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.progress-text {
    text-align: center;
    font-size: 25px;
    letter-spacing: 0px;
    color: #525252;
}

.progress-container {
    width: 320px;
    height: 10px;
    background: #cdcdcd 0% 0% no-repeat padding-box;
    border-radius: 10px;
}
.progress-filler {
    height: 10px;
    background: #037cb7 0% 0% no-repeat padding-box;
    border-radius: 10px;
}

.progress-bar-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.date-range-picker {
    position: absolute;
    z-index: 100;
  }
  
  .date-range-labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2px;
    position: relative;
    z-index: 100;
    
  }
  
  .from-label, .to-label {
    flex: 1; /* Consume 80% of the width */
    text-align: center;
    color: #015294;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 6px 4px;
    cursor: default;
    transition: background-color 0.3s ease;
    margin-right: 10px; /* Add gap between labels */
    margin-left: 10px;
    line-height: 1.6; /* Adjust line height */
    font-size: 13px; /* Increase font size */
    /* font-weight: bold; */
    font-family: "Open Sans", sans-serif;
}

.from-label:last-child, .to-label:last-child {
    margin-right: 0; /* Remove gap for the last label */
}

/* src/custom-styles.css */
.rdrNextPrevButton, 
.rdrPprevButton {
    background: #037CB7;
    color: #FFFFFF; /* White text for any text content */
    border: none; /* Remove any borders */
    height: 30px;
    width: 30px;
  }
  
  .rdrNextPrevButton:hover, .rdrPprevButton:hover {
    background-color: #025780;
  }
  /* Ensure the arrows are white */
.rdrNextPrevButton::before, .rdrNextPrevButton::after {
    color: #FFFFFF; /* White color for pseudo-elements */
    fill: #FFFFFF; /* White color for SVGs if used */
  }

  /* Target the month and year pickers */
.rdrMonthAndYearPickers .rdrMonthPicker{
    color: #037CB7; /* Set text color to #037CB7 */
    font-size: 13px; /* Increase font size */
    font-weight: bold; 
    font-family: "Open Sans", sans-serif;
  }

  /* Optionally, you can add styles for the dropdowns or inputs within the pickers */
  .rdrMonthAndYearPickers select,
  .rdrMonthAndYearPickers input {
    color: #037CB7; /* Set text color for inputs and selects to #037CB7 */
    font-size: 13px; /* Increase font size */
    font-weight: bold; 
    font-family: "Open Sans", sans-serif;
  }
  

  .rdrMonthName {
    color: #037CB7; /* Set text color for inputs and selects to #037CB7 */
    font-size: 14px; /* Increase font size */
    font-weight: semi-bold; 
    font-family: "Open Sans", sans-serif;
  }

  .rdrWeekDays,.rdrWeekDay{
    color: #037CB7; /* Set text color for inputs and selects to #037CB7 */
   
    font-weight: semi-bold; 
    font-family: "Open Sans", sans-serif;
  }

  .rdrDayDisabled {
    background-color: rgba(46, 46, 46, 0.15);
  }

.rdrNextPrevButton:disabled {
    cursor: not-allowed !important;  /* Ensure cursor changes */
    background-color: #AAAAAA !important;  /* Ensure background changes */
    color: #FFFFFF !important;  /* Ensure text/icon color stays white */
    border: none; /* Make sure no border is shown */
}


.rdrNextPrevButton:disabled {
    cursor: not-allowed !important; /* Ensures the default arrow cursor */
}
/* Hide default next/prev arrows */
.rdrNextButton i, .rdrPprevButton  i {
  display: none;
}

/* Customize the previous button with Font Awesome icon */
.rdrPprevButton::before {
  margin: 1px 3px 0px -1px;
  content: '\f104'; /* Unicode for left chevron in FontAwesome */
  font-family: 'Font Awesome 5 Free'; /* Correct Font Awesome font-family */
  font-weight: 600; /* Set font-weight to ensure solid icon style */
  font-size: 23px;
  display: inline-block;
}

/* Customize the next button with Font Awesome icon */
.rdrNextButton::before {
    margin: 1px 3px 0px 3px;
    content: '\f105'; /* Unicode for right chevron in FontAwesome */
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    font-size: 23px;
    display: inline-block;
}
